<template>
  <div class="align-items-center" v-if="modelValue?.multiple">
    <span
      class="badge"
      :class="{
        'successful text-white': getAvg() === 1,
        'warning text-dark': getAvg() === 2,
        'failed text-white': getAvg() === 3,
        'light text-dark': getAvg() == null,
      }"
    >
      <template v-if="getAvg() == null"> New product </template>
      <template v-else> Avg. {{ getValue(getAvg()) }}-grade </template>
    </span>
  </div>
  <div class="align-items-center" v-else>
    <span
      class="badge"
      :class="{
        'successful text-white': modelValue === 1,
        'warning text-dark': modelValue === 2,
        'failed text-white': modelValue === 3,
        'light text-dark': modelValue == null,
      }"
    >
      <template v-if="modelValue == null"> New product </template>
      <template v-else> {{ getValue(modelValue) }}-grade </template>
    </span>
  </div>
</template>
<script>
export default {
  name: "ABCGrade",
  props: {
    id: Number,
    row: Object,
    modelValue: Boolean,
    extraRow: String,
  },
  methods: {
    getAvg() {
      if (this.modelValue?.values?.length === 0) {
        return null;
      }
      let sum = 0;
      let count = 0;
      for (const i in this.modelValue.values) {
        sum +=
          this.modelValue.values[i].value * this.modelValue.values[i].count;
        count += this.modelValue.values[i].count;
      }
      return Math.round(sum / count);
    },
    getValue(a) {
      if (a === 1) {
        return "A";
      }
      if (a === 2) {
        return "B";
      }
      if (a === 3) {
        return "C";
      }
      return "-";
    },
  },
};
</script>
